<template>
  <div class="mr-4 md:mr-0">
    <trac-loading v-if="isLoading" />
    <div class="wrapper" v-if="stores">
      <trac-back-button>terminals</trac-back-button>
      <div class="flex justify-between items-center">
        <h2>New device</h2>
        <trac-button :disabled="
          terminal.assignment.store_id === '' ||
          terminal.terminal_name === '' ||
          terminal.terminal_type === '' ||
          terminal.assignment.serial_no === ''
        " @button-clicked="EditTerminal">{{ updateFlag ? 'Save' : 'Save' }}</trac-button>
      </div>
      <div class="md:w-8/12">
        <div class="mt-12 border-2 border-gray-100 p-12 bg-white md:ml-8">
          <h3>Device Info</h3>
          <trac-input v-model.trim="terminal.terminal_name" type="text" placeholder="Device Name" class="mt-6" />
          <trac-input :disabled="updateFlag" v-model.trim="terminal.assignment.serial_no" type="text" placeholder="Serial Number" class="mt-6" />
        </div>
        <div class="my-12 border-2 border-gray-100 p-12 bg-white md:ml-8">
          <h3>Assignment</h3>
          <trac-dropdown-exteneded data-test="assignment" @optionSelected="selectOption($event)" placement="left" :options="stores"
            :neededProperty="'name'" class="my-6" :selector="
              updateFlag ? terminal.assignment.store_name : 'Select Store'
            ">
          </trac-dropdown-exteneded>
          <trac-dropdown-exteneded @optionSelected="selectTerminalType($event)" placement="left" :options="terminals"
            :neededProperty="'name'" class="my-6" :selector="
              updateFlag ? terminal.assignment.terminal_name : 'Select Terminal Type'
            ">
          </trac-dropdown-exteneded>
          <trac-input :disabled="updateFlag" v-model.trim="terminal.assignment.activation_code" type="text" placeholder="Activation Code"
            class="mt-6" />
          <p v-if="!updateFlag" class="text-xs text-primaryBlue mt-3 font-semibold">Kindly check your email, this code was
            shared when you requested the terminal.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      isLoading: false,
      updateFlag: false,
      stores: null,
      selectedStore: null,
      selectedTerminal: null,
      terminals: [
        {
          id: 'android',
          name: 'Android',
        },
        {
          id: 'Linux',
          name: 'Linux',
        },
        {
          id: 'mpos',
          name: 'Mpos',
        },
      ],
      terminal: {
        serial_no: "",
        terminal_name: "",
        terminal_type: "",
        assignment: {
          business_id: GET_USER_BUSINESS_ID(),
          date_assigned: "",
          serial_no: "",
          activation_code: "",
          store_id: "",
          store_name: "",
          terminal_id: "",
          terminal_name: "",
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchTerminalInfo();
    await this.fetchAllStores();
    this.isLoading = false;
  },
  computed: {
    // terminal() {
    //   return this.$store.getters["GET_SINGLE_TERMINAL"];
    // },
  },
  methods: {
    async fetchTerminalInfo() {
      this.isLoading = true;
      let { terminal, serial } = this.$route.params;

      let k = {
        terminalProviderId: "",
        serialNo: "",
        businessID: "",
      };

      k.terminalProviderId = terminal;
      k.serialNo = serial;
      k.businessID = GET_USER_BUSINESS_ID();
      if (k.terminalProviderId && k.serialNo) {
        await this.$store.dispatch("FETCH_TERMINAL_BY_SERIALNUMBER", k);
        const res = this.$store.getters["GET_SINGLE_TERMINAL"];
        if (res.status) {
          this.updateFlag = true;
          this.terminal = res.data.items;
        }
      } else {
        await this.$store.dispatch("FETCH_ALL_TERMINAL_NAMES");
      }
      this.isLoading = false;
    },
    selectOption(option) {
      this.selectedStore = option;
      this.terminal.assignment.store_id = this.selectedStore._id;
      this.terminal.assignment.store_name = this.selectedStore.name;
    },
    selectTerminalType(option) {
      this.selectedTerminal = option;
      this.terminal.terminal_type = option.id;
      // this.terminal.assignment.terminal_name = option.name;
    },
    async fetchAllStores() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
      this.isLoading = false;
    },
    async EditTerminal() {
      this.isLoading = true;

      // const payload = {
      //   _id: this.terminal._id,
      //   updated_at: this.terminal.updated_at,
      //   is_assigned: this.terminal.is_assigned,
      //   created_at: this.terminal.created_at,
      //   store_id: this.terminal.assignment.store_id,
      //   store_name: this.terminal.assignment.store_name,
      //   terminal_name: this.terminal.assignment.terminal_name,
      //   business_id: GET_USER_BUSINESS_ID(),
      //   serial_no: this.terminal.assignment.serial_no,
      //   terminal_name: this.terminal.terminal_name,
      //   terminal_type: this.terminal.terminal_type,
      // };

      const activationPayload = {
        "activation_code": this.terminal.assignment.activation_code,
        "business_id": GET_USER_BUSINESS_ID(),
        "mac_address": "",
        "serial_no": this.terminal.assignment.serial_no,
        "store_id": this.terminal.assignment.store_id,
        "store_name": this.terminal.assignment.store_name,
        "terminal_name": this.terminal.terminal_name,
        "terminal_type": this.terminal.terminal_type,
      };


      const res = await this.$store.dispatch(
        "ASSIGN_TERMINAL",
        activationPayload
      );

      if (res.success || res.status) {
        eventBus.$emit("trac-alert", { message: res.message });
        this.$router.back();
      } else {
        // eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
